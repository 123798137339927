<template>
  <div>
    <user-create :user-update="userUpdate" :title="$t('users_management.page_title.update')" :button-text="$t('users_management.buttons.update_user')"></user-create>
  </div>
</template>

<script>
import UserCreate from "@/views/users/UserCreate";
import {UserService} from "@/services";

export default {
  name: "UserUpdate",
  components: {UserCreate},
  data() {
    return {
      userUpdate: {}
    }
  },
  computed: {
    userId() {
      return this.$route.params.id
    }
  },
  created() {
    if (this.userId) {
      UserService.detail(this.userId).then((res) => {
        if(res && res.data) {
          this.userUpdate = res.data
        }
      }).catch((err) => {
        this.$toast.error(err.data.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>